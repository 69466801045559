<template>
  <div>
    <div class="flex flex-wrap border border-blue-200">
      <div
        class="w-full sm:w-1/2 md:w-1/2 xl:w-1/3 border-b sm:border-b-0 sm:border-r border-blue-200 py-4 px-6"
      >
        <div class="mb-10">
          <sm-loader v-if="loading" />
          <div class="flex items-center" v-else>
            <div>
              <div class="mb-5">
                <h1 class="text-2xl font-bold mb-2">
                  {{ staff.name }} {{ staff.last_name }}
                </h1>
                <div
                  class="text-gray-600 text-xs font-light mb-3"
                  v-if="staff.company"
                >
                  {{ position | sentenceCase }} at {{ staff.company.name }}
                </div>
              </div>
              <div class="inline-flex">
                <div v-html="progressIcons(staff)"></div>
              </div>
              <div class="inline-flex">
                 <div class="mb-10" v-if="userPNDRestriction">

                  <div class="ml-5">
                    <!-- <span class="alert-icon">!</span> -->
                    <div class="badge badge-orange-soft-outline">Post No Debit</div>
                  </div>

                </div>
              </div>
            </div>

            <!-- <div class="dropdown ml-auto">
              <button type="button" class="flex flex-col focus:outline-none" dropdown>
                <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                <div class="h-1 w-1 bg-gray-400 rounded-full"></div>
              </button>
              <ul class="dropdown-menu">
                <a href="#" class="dropdown-menu-item text-red-500">Delete</a>
              </ul>
            </div> -->
          </div>
        </div>

        <div class="mb-5" v-if="bankNeedsVerification">
          <div class="alert alert-orange items-start mb-5">
            <div class="alert-icon flex">
              <ion-icon name="alert" />
            </div>
            <div>
              <div class="font-bold">Bank Account Needs Verification!</div>
              <div class="font-thin">
                {{ staff.name }} has uploaded their bank account information.
                However, this information could not be verified automatically.
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-orange"
            @click.prevent="() => (tab = 'verification')"
          >
            Click to Verify
          </button>
        </div>

        <div class="mb-10">
          <div class="mb-5" v-if="canMarkAsContacted">
            <sm-loader v-if="loading" />
            <button
              v-else
              type="button"
              class="btn btn-sm btn-blue"
              :disabled="contactForm.loading"
              @click.prevent="markAsContacted"
            >
              <sm-loader class="sm-loader-white" v-if="contactForm.loading" />
              <span v-else>Contacted</span>
            </button>
          </div>

          <div class="mb-5">
            <div class="opacity-75 text-sm font-medium mb-1">
              Account Verification Status
            </div>
            <sm-loader v-if="loading" />
            <div v-else>
              <div
                class="text-gray-500 text-sm font-light flex flex-wrap items-center"
              >
                <ion-icon
                  name="alert-circle-outline"
                  class="text-lg mr-2"
                ></ion-icon>
                {{ staff && staff.status | fromSlug | sentenceCase }}
              </div>
              <div class="flex items-start" v-if="canVerify">
                <span class="text-gray-500 text-sm font-light mr-2 mt-2">
                  Change Status:
                </span>
                <div class="flex-grow">
                  <custom-select
                    :options="verificationStatusOptions"
                    class="inline-flex relative text-sm text-green-500 border border-green-500 rounded-sm pl-3 py-2 pr-3 mr-3 mb-3 align-middle"
                    v-model="statusForm.data.status.value"
                    placeholder="Set verification status"
                  ></custom-select>

                  <form-group
                    type="textarea"
                    name="comments"
                    :form="statusForm"
                    v-model="statusForm.data.comments.value"
                    v-if="needsComments"
                  >
                    Comments.
                  </form-group>

                  <button
                    type="button"
                    class="btn btn-sm btn-blue align-middle -mt-2"
                    :disabled="statusForm.loading"
                    @click="saveVerificationStatus"
                    v-if="statusForm.changed"
                  >
                    <sm-loader
                      class="sm-loader-white"
                      v-if="statusForm.loading"
                    />
                    <span v-else>Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="border-t border-blue-200 mb-5 pt-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Account Approval Status
            </div>
            <sm-loader v-if="loading" />
            <div v-else>
              <div
                class="text-gray-500 text-sm font-light flex flex-row items-center"
              >
                <ion-icon
                  name="alert-circle-outline"
                  class="text-lg mr-2"
                ></ion-icon>
                {{
                  staff && staff.profile
                    ? staff.profile.status
                    : 'Pending' | fromSlug | sentenceCase
                }}
              </div>
              <div class="flex items-start" v-if="canApproveOrDecline">
                <span class="text-gray-500 text-sm font-light mr-2">
                  Change Status:
                </span>
                <div class="flex-grow">
                  <custom-select
                    :options="profileStatusOptions"
                    class="inline-flex relative text-sm text-green-500 border border-green-500 rounded-sm pl-3 py-2 pr-3 mr-3 mb-3 align-middle"
                    v-model="approval.data.status.value"
                    placeholder="Set account status"
                  ></custom-select>

                  <form-group
                    type="textarea"
                    name="comments"
                    :form="approval"
                    v-model="approval.data.comments.value"
                    v-if="approvalComments"
                  >
                    Comments.
                  </form-group>

                  <button
                    type="button"
                    class="btn btn-sm btn-blue"
                    :disabled="approval.loading"
                    @click="saveProfileStatus"
                    v-if="approval.changed"
                  >
                    <sm-loader
                      class="sm-loader-white"
                      v-if="approval.loading"
                    />
                    <span v-else>Save</span>
                  </button>
                </div>
              </div>

              <!-- <div v-if="canIncreaseLimits" class="mt-4">
                <button
                  type="button"
                  class="btn btn-green btn-sm"
                  @click.prevent="$refs.increaseLimit.open"
                >
                  Increase Limits
                </button>
              </div> -->

              <div class="mt-4">
                <div v-if="!isEmployed" class="mr-2">
                  <span class="badge badge-orange">
                    {{ employmentStatus | fromSlug }}
                  </span>
                </div>
                <div
                  v-if="staff && staff.profile && staff.profile.verify_method"
                  class="mr-2"
                >
                  <span
                    class="badge badge-blue"
                    :class="
                      staff.profile.verify_method === 'express'
                        ? 'bg-express'
                        : 'bg-statement'
                    "
                  >
                    {{
                      staff.profile.verify_method === 'manual'
                        ? 'Bank Statement'
                        : 'Express'
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- to be added back later -->
          <!-- <div class="border-t border-blue-200 mb-5 pt-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Debit Card Status
            </div>
            <sm-loader v-if="loading" />
            <div
              v-else
            >
              <div class="text-gray-500 text-sm font-light flex flex-row items-center">
                <ion-icon name="alert-circle-outline" class="text-lg mr-2"></ion-icon>
                {{ staff.can_have_debit ? 'Enabled' : "Disabled" }}
              </div>
              <div
                class="flex items-center"

              >
                <span class="text-gray-500 text-sm font-light mr-2">Change Status: </span>
                <custom-select
                  :options="debitCardOption"
                  class="inline-block relative text-sm text-green-500 border border-green-500 rounded-sm pl-3 py-2 pr-3 mr-3"
                  v-model="debitCardForm.data.status.value"
                  placeholder="Debit card status"
                ></custom-select>
                <button
                  type="button"
                  class="btn btn-sm btn-blue"
                  :disabled="debitCardForm.loading"
                  @click="toggleDebitCardActivation"
                  v-if="debitCardForm.changed"
                >
                  <sm-loader class="sm-loader-white" v-if="debitCardForm.loading" />
                  <span v-else>Save</span>
                </button>
              </div>
            </div>
          </div> -->

          <div class="border-t border-blue-200 mb-5 pt-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Email Address
            </div>
            <sm-loader v-if="loading" />
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
              v-else
            >
              <ion-icon name="mail-outline" class="text-lg mr-2"></ion-icon>
              {{ staff.email }}
            </div>
          </div>
          <div class="border-t border-blue-200 mb-5 pt-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Phone Number
            </div>
            <sm-loader v-if="loading" />
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
              v-else
            >
              <ion-icon name="call-outline" class="text-lg mr-2"></ion-icon>
              {{ staff.phone_no }}
            </div>
          </div>
          <div class="border-t border-blue-200 mb-5 pt-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Additional Phone Numbers
            </div>
            <sm-loader v-if="loading" />
            <div v-else>
              <div
                class="text-gray-500 text-sm font-light flex flex-wrap items-center"
              >
                <ion-icon name="call-outline" class="text-lg mr-2"></ion-icon>
                <div
                  v-for="(number, i) in staff.profile &&
                    staff.profile.additional_phone"
                  :key="i"
                >
                  {{ number
                  }}<span
                    v-if="
                      i !=
                        (staff.profile &&
                          staff.profile.additional_phone.length - 1)
                    "
                    >,
                  </span>
                </div>
              </div>
              <template v-if="additionalPhoneError">
                <div class="alert alert-red-soft mb-6 mt-4">
                  <div class="alert-icon">!</div>
                  <span>{{ additionalPhoneError }}</span>
                </div>
              </template>
              <!-- <div class="mt-4"> -->
              <div class="flex-grow mt-4">
                <form-group
                  type="textarea"
                  name="additionalPhone"
                  :form="additionalPhoneForm"
                  v-model="additionalPhoneForm.data.additionalPhone.value"
                  v-if="additionalPhoneForm.changed"
                >
                  Numbers.
                </form-group>
                <div>
                  <button
                    class="btn btn-sm btn-blue mr-4"
                    :disabled="additionalPhoneForm.loading"
                    @click.prevent="editAdditionalPhone"
                  >
                    <span v-if="additionalPhoneForm.changed">Cancel</span>
                    <span v-else>Add</span>
                  </button>
                  <button
                    class="btn btn-sm btn-blue"
                    :disabled="additionalPhoneForm.loading"
                    @click.prevent="updateUserProfile"
                    v-if="additionalPhoneForm.changed"
                  >
                    <span v-if="additionalPhoneForm.loading">Saving...</span>
                    <span v-else>Save</span>
                  </button>
                </div>
              </div>
              <!-- </div> -->
            </div>
          </div>
          <div class="border-t border-blue-200 mb-5 pt-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              BVN Number
            </div>
            <sm-loader v-if="loading" />
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
              v-else
            >
              <ion-icon
                name="finger-print-outline"
                class="text-lg mr-2"
              ></ion-icon>
              {{
                staff.profile && staff.profile.bvn ? staff.profile.bvn : 'N/A'
              }}
            </div>
          </div>
          <div class="border-t border-blue-200 mb-5 pt-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              NIN Number
            </div>
            <sm-loader v-if="loading" />
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
              v-else
            >
              <ion-icon
                name="finger-print-outline"
                class="text-lg mr-2"
              ></ion-icon>
              {{
                staff.profile && staff.profile.nin ? staff.profile.nin : 'N/A'
              }}
            </div>
          </div>
          <div class="border-t border-blue-200 mb-5 pt-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Company
            </div>
            <sm-loader v-if="loading" />
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
              v-else
            >
              <ion-icon name="business-outline" class="text-lg mr-2"></ion-icon>
              {{
                (staff.company ? staff.company.name : null) ||
                  (staff.profile ? staff.profile.employer : null) ||
                  '---'
              }}
            </div>
          </div>
          <div class="border-t border-blue-200 mb-5 pt-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Source
            </div>
            <sm-loader v-if="loading" />
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
              v-else
            >
              <ion-icon
                name="arrow-redo-outline"
                class="text-lg mr-2"
              ></ion-icon>
              {{ staff.source ? staff.source : '---' }}
            </div>
          </div>

          <div
            class="border-t border-blue-200 mb-5 pt-4"
            v-if="staff && staff.profile && staff.profile.verify_method"
          >
            <div class="opacity-75 text-sm font-medium mb-1">
              Verification Method
            </div>
            <sm-loader v-if="loading" />
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
              v-else
            >
              <ion-icon
                name="arrow-redo-outline"
                class="text-lg mr-2"
              ></ion-icon>
              {{ staff.profile.verify_method.toUpperCase() }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full sm:w-1/2 md:w-1/2 xl:w-2/3 py-4 px-6"
        style="min-height: 600px"
      >
        <loader v-if="loading" />

        <template v-else>
          <tabs v-model="tab" :tabs="tabs" class="mb-6" />

          <component :is="tab" :staff="staff" @success="getUser" />
        </template>
      </div>
    </div>

    <modal class-name="w-full md:w-2/5 xl:w-3/10" ref="modal">
      <h1 class="text-xl font-bold mb-10">Approve This User For Credit</h1>

      <form @submit.prevent="approve">
        <template v-if="getFormError(approval)">
          <div class="alert alert-red-soft mb-10">
            <div class="alert-icon">!</div>
            <span>{{ getFormError(approval) }}</span>
          </div>
        </template>

        <form-group
          type="money"
          left-icon="card-outline"
          name="credit_limit"
          :form="approval"
          v-model="approval.data.credit_limit.value"
        >
          Credit Limit
        </form-group>

        <form-group
          type="money"
          left-icon="card-outline"
          name="loan_limit"
          :form="approval"
          v-model="approval.data.loan_limit.value"
        >
          Standard Loan Limit
        </form-group>

        <form-group
          left-icon="bar-chart-outline"
          name="interest_rate"
          :form="approval"
          v-model="approval.data.interest_rate.value"
        >
          Standard Loan Interest Rate
        </form-group>

        <form-group
          left-icon="calendar-outline"
          name="billing_date"
          :form="approval"
          v-model="approval.data.billing_date.value"
        >
          Billing Date
        </form-group>

        <form-group
          type="number"
          left-icon="calendar-outline"
          name="salary_date"
          :form="approval"
          v-model="approval.data.salary_date.value"
        >
          Salary Date
        </form-group>

        <!-- <form-group
          left-icon="calendar-outline"
          name="payment_date"
          :form="approval"
          v-model="approval.data.payment_date.value"
        >
          Payment Date
        </form-group> -->

        <div class="text-center">
          <button
            type="submit"
            class="btn btn-md btn-blue"
            :disabled="approval.loading"
          >
            <span v-if="approval.loading">Approving</span>
            <span v-else>Approve Credit</span>
          </button>
        </div>
      </form>
    </modal>

    <modal class-name="w-full sm:max-w-500px" ref="assignmentModal">
      <h1 class="text-xl font-bold mb-10">Approve This User For Credit</h1>
    </modal>

    <increase-limit
      v-if="staff"
      ref="increaseLimit"
      :selected-user="staff"
      @success="getUser"
    />
  </div>
</template>

<script>
export default {
  components: {
    admins: () => import('./Admins'),
    card: () => import('./Cards'),
    cashback: () => import('./Cashback'),
    comments: () => import('./Comments'),
    creditCardPayments: () => import('./CreditCardPayments'),
    documents: () => import('./Documents'),
    increaseLimit: () => import('./IncreaseLimit'),
    loans: () => import('./Loans'),
    loanbot: () => import('./loanbot/Index'),
    orderHistory: () => import('./OrderHistory'),
    profile: () => import('./Profile'),
    referrals: () => import('./Referrals'),
    repaymentWallet: () => import('./RepaymentWallet'),
    statements: () => import('./Statements'),
    transactions: () => import('./Transactions'),
    verification: () => import('./Verification'),
    wallet: () => import('./Wallet')
  },
  data() {
    return {
      loading: false,
      staff: null,
      tab: 'profile',
      verifying: false,
      refuting: false,
      deactivating: false,
      profileStatus: 'pending',
      uncertainComments: '',
      // isSuperAdmin: this.isSuperAdmin(),
      // isSuperRisk: this.isSuperRisk(),
      statusForm: this.$options.basicForm(['status', 'comments'], {
        changed: false,
        lastStatus: 'new'
      }),
      approval: this.$options.basicForm(
        [
          'credit_limit',
          'loan_limit',
          'interest_rate',
          'billing_date',
          'salary_date',
          'status',
          'comments'
        ],
        { changed: false }
      ),
      debitCardForm: this.$options.basicForm(['status'], { changed: false }),
      contactForm: this.$options.basicForm([]),
      additionalPhoneForm: this.$options.basicForm(['additionalPhone'], {
        changed: false,
        loading: false
      }),
      additionalPhoneError: ''
    };
  },
  computed: {
    approvalComments() {
      return (
        this.approval.data.status.value?.match(
          /uncertain|declined|not_suitable/
        ) && this.approval.changed
      );
    },
    assignedAdmins() {
      return this.staff?.admins;
    },
    bankNeedsVerification() {
      return (
        this.staff?.profile?.trial_account_no &&
        this.staff?.profile?.trial_bank_code
      );
    },
    canVerify() {
      return (
        !this.staff?.status?.match(/processed/) &&
        !this.staff?.profile?.status.match(/verified/)
      );
    },
    canApproveOrDecline() {
      return (
        (this.isSuperRisk || this.isSuperAdmin) &&
        (this.staff?.profile?.status?.match(/verified/) ||
          this.staff?.profile?.status?.match(
            /activated|pre-approved|deactivated/
          ))
      );
    },
    canIncreaseLimits() {
      return (
        this.canApproveOrDecline && this.validateUser('approval', this.staff)
      );
    },
    canMarkAsContacted() {
      return this.staff?.admins?.find(
        admin =>
          admin.id === this.user.id && admin.pivot?.status !== 'contacted'
      );
    },
    daysOfTheMonth() {
      const days = [];

      for (var i = 0; i < 31; i++) {
        const day = i + 1;
        const value = day;
        const title = day.toPosition();
        days.push({ title, value });
      }

      return days;
    },
    debitCardOption() {
      return [
        { title: 'enable', value: 'enable' },
        { title: 'disable', value: 'disable' }
      ];
    },
    department() {
      return this.$route.query.department;
    },
    employmentStatus() {
      return this.staff?.profile?.employment_status;
    },
    hasStaffRole() {
      return this.staff?.roles?.find(role => role.slug == 'company_staff');
    },
    isAssignedToAdmin() {
      return this.assignedAdmins.find(admin => admin.id === this.user?.id);
    },
    isAssignedToAdminInDepartment() {
      return this.assignedAdmins.find(admin =>
        admin.assignables?.find(
          assignable => assignable.type === this.department
        )
      );
    },
    isEmployed() {
      return this.employmentStatus == 'employed';
    },
    needsComments() {
      return (
        this.statusForm.data.status.value?.match(
          /self_employed|not_suitable|not_interested/
        ) && this.statusForm.changed
      );
    },
    position() {
      return this.staff?.profile ? this.staff?.profile.position : 'Staff';
    },
    profileStatusOptions() {
      return [
        { title: 'Approved', value: 'activated' },
        // { title: "Uncertain", value: "uncertain" },
        // { title: 'Deactivated', value: 'deactivated' },
        { title: 'Declined', value: 'declined' }
      ];
    },
    staffId() {
      return this.$route.params.staffId;
    },
    tabs() {
      return [
        { name: 'profile', title: 'Profile' },
        { name: 'documents', title: 'Documents' },
        {
          name: 'verification',
          title: `Verification ${
            this.bankNeedsVerification
              ? '<span class="alert-icon bg-blue-500 ml-1 text-white"><ion-icon name="alert" /></span>'
              : ''
          }`
        },
        { name: 'transactions', title: 'Transactions' },
        { name: 'orderHistory', title: 'Order History' },
        { name: 'admins', title: 'Assignments' },
        { name: 'loans', title: 'Loans' },
        { name: 'card', title: 'Cards' },
        { name: 'credit-card-payments', title: 'Credit Card Payments' },
        { name: 'statements', title: 'Statements' },
        { name: 'loanbot', title: 'LoanBot' },
        { name: 'comments', title: 'Comments' },
        { name: 'wallet', title: 'Wallet' },
        { name: 'cashback', title: 'Cashbacks' },
        { name: 'referrals', title: 'Referrals' },
        { name: 'repayment-wallet', title: 'Repayment Wallet' }
      ];
    },
    userPNDRestriction() {
      return this.staff?.pnd == 1;
    },
    verificationStatusOptions() {
      return [
        // { title: 'New', value: 'new' },
        // { title: 'Awaiting', value: 'awaiting' },
        // { title: 'Cold', value: 'cold' },
        // { title: 'Processing', value: 'processing' },
        { title: 'Verified', value: 'verified' }
        // { title: "Self Employed", value: "self_employed" },
        // { title: "Not Suitable", value: "not_suitable" },
        // { title: 'Not Interested', value: 'not_interested' },
      ];
    }
  },
  beforeMount() {
    this.getUser();
  },
  mounted() {
    this.tab = this.$route.query.tab ?? this.tab;
  },
  watch: {
    'statusForm.data.status.value'(value) {
      if (
        value.match(/new|awaiting|processing/i) ||
        value == this.statusForm.lastStatus
      ) {
        this.statusForm.changed = false;
      } else {
        this.statusForm.changed = value != this.staff.status ? true : false;
      }

      if (value.match(/self_employed|not_suitable/)) {
        this.statusForm.data.comments.rules = 'required';
      } else {
        this.statusForm.data.comments.rules = 'nullable';
      }
    },
    'approval.data.status.value'(value) {
      if (value.match(/pending/i)) {
        return (this.approval.changed = false);
      }

      if (value.match(/uncertain|not_suitable/)) {
        this.approval.data.comments.rules = 'required';
        this.approval.data.credit_limit.rules = 'nullable';
        this.approval.data.loan_limit.rules = 'nullable';
        this.approval.data.interest_rate.rules = 'nullable';
        this.approval.data.billing_date.rules = 'nullable';
        this.approval.data.salary_date.rules = 'nullable';
      } else {
        this.approval.data.comments.rules = 'nullable';
      }

      this.approval.changed =
        value != this.staff?.profile?.status ? true : false;
    },
    'debitCardForm.data.status.value'(value) {
      if (value.match(/pending/i)) {
        return (this.approval.changed = false);
      }
      this.debitCardForm.changed =
        value != this.staff?.can_have_debit ? true : false;
    },
    tab(tab) {
      if (tab !== this.$route.query.tab) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            tab
          }
        });
      }
    }
  },
  methods: {
    confirm() {
      this.$refs.modal.open();
      this.approval.data.credit_limit.value =
        this.staff?.profile?.credit_limit || '';
      this.approval.data.loan_limit.value =
        this.staff?.profile?.standard_loan_limit || '';
    },
    saveVerificationStatus() {
      switch (this.statusForm.data.status.value) {
        case 'new':
        case 'awaiting':
        case 'processing':
          break;
        case 'verified':
          this.verify();
          break;
        case 'self_employed':
        case 'not_suitable':
        case 'not_interested':
          this.updateStatus();
          break;
        default:
          // statements_def
          break;
      }
    },
    saveProfileStatus() {
      switch (this.approval.data.status.value) {
        case 'pending':
          break;
        case 'uncertain':
          this.uncertainProfile();
          break;
        case 'activated':
          this.confirm();
          break;
        case 'deactivated':
          this.deactivate();
          break;
        case 'declined':
          this.decline();
          break;
        default:
          // statements_def
          break;
      }
    },
    editAdditionalPhone() {
      const staffNumber = this.staff?.profile?.additional_phone?.toString();
      this.additionalPhoneForm.data.additionalPhone.value = staffNumber;
      this.additionalPhoneForm.changed = !this.additionalPhoneForm.changed;
    },
    checkPhone(data) {
      let check = true;
      data.map(number => {
        const toCheck =
          number &&
          number
            .toString()
            .trim()
            .match(/^(\+|)(234|0)(7|8|9)(0|1)\d{8}$/);
        if (!toCheck) check = false;
      });
      return check;
    },
    async getUser() {
      this.loading = true;
      const query = {};
      if (this.department) {
        query['assign-to'] = this.department;
      }
      await this.sendRequest('admin.users.show', this.staffId, query, {
        success: response => {
          this.staff = response.data.data.user;
          this.approval.data.salary_date.value =
            this.staff.profile?.salary_day || '';
          this.statusForm = this.resetForm(this.statusForm);

          if (this.staff.profile?.status == 'verified') {
            this.statusForm.data.status.value = 'verified';
            this.statusForm.lastStatus = 'verified';
            this.approval.data.status.value = 'pending';
          } else {
            this.statusForm.data.status.value = this.staff.status;
          }
        },
        error: error => {
          console.log(error);
        }
      });
      this.loading = false;
    },
    async markAsContacted() {
      this.contactForm.error = null;
      this.contactForm.loading = true;

      await this.sendRequest('admin.assignments.contact', {
        data: {
          user_id: this.staffId,
          admin_id: this.user.id
        },
        success: () => {
          this.getUser();
        },
        error: error => {
          this.contactForm.error = error;
        }
      });

      this.contactForm.loading = false;
    },
    async verify() {
      this.statusForm.loading = true;
      this.statusForm.error = null;

      await this.sendRequest('admin.users.verify', {
        data: {
          user_id: this.staff.id
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'User profile and documents verified',
            button: 'Okay'
          });
          this.getUser();
          this.statusForm = this.resetForm(this.statusForm);
        },
        error: error => {
          this.approval.error = error;
          this.mapFormErrors(this.approval, error.response?.data?.errors);
        }
      });

      this.statusForm.loading = false;
    },
    async deactivate() {
      this.approval.loading = true;
      this.approval.error = null;

      await this.sendRequest('admin.users.deactivate', {
        data: {
          user_id: this.staff.id
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'User profile deactivated and application declined',
            button: 'Okay'
          });
          this.getUser();
          this.approval = this.resetForm(this.approval);
        },
        error: error => {
          this.approval.error = error;
          this.mapFormErrors(this.approval, error.response?.data?.errors);
        }
      });

      this.approval.loading = false;
    },
    async refute() {
      this.approval.loading = true;
      this.approval.error = null;

      await this.sendRequest('admin.users.decline', {
        data: {
          user_id: this.staff.id
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'User profile deactivated and application declined',
            button: 'Okay'
          });
          this.getUser();
          this.approval = this.resetForm(this.approval);
        },
        error: error => {
          this.approval.error = error;
          this.mapFormErrors(this.approval, error.response?.data?.errors);
        }
      });

      this.approval.loading = false;
    },
    async approve() {
      this.approval.loading = true;
      this.approval.error = null;

      await this.sendRequest('admin.users.approve', {
        data: {
          user_id: this.staff.id,
          ...this.getFormData(this.approval)
        },
        success: () => {
          this.getUser();
          this.$refs.modal.close();
          this.$success({
            title: 'Operation Successful',
            body: "User's account has been activated",
            button: 'Okay'
          });
          setTimeout(() => {
            this.approval = this.resetForm(this.approval);
          }, 1000);
        },
        error: error => {
          this.approval.error = error;
          this.mapFormErrors(this.approval, error.response?.data?.errors);
        }
      });

      this.approval.loading = false;
    },
    async decline() {
      this.approval.loading = true;
      this.approval.error = null;

      await this.sendRequest('admin.users.decline', {
        data: {
          user_id: this.staff.id,
          ...this.getFormData(this.approval)
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'User profile deactivated and application declined',
            button: 'Okay'
          });
          this.getUser();
          this.approval = this.resetForm(this.approval);
        },
        error: error => {
          this.approval.error = error;
        }
      });

      this.approval.loading = false;
    },
    async updateStatus() {
      if (!this.validateForm(this.statusForm)) {
        return false;
      }

      this.statusForm.loading = true;
      this.statusForm.error = null;

      await this.sendRequest('admin.users.updateStatus', {
        data: {
          user_id: this.staff.id,
          status: this.statusForm.data.status.value,
          comments: this.statusForm.data.comments.value
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: "User's profile status updated",
            button: 'Okay'
          });
          this.getUser();
          this.statusForm = this.resetForm(this.statusForm);
        },
        error: error => {
          this.statusForm.error = error;
        }
      });

      this.statusForm.loading = false;
    },
    async uncertainProfile() {
      if (!this.validateForm(this.approval)) {
        return false;
      }
      this.approval.loading = true;
      this.approval.error = null;

      await this.sendRequest('admin.users.uncertain', {
        data: {
          user_id: this.staff.id,
          comments: this.approval.data.comments.value
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'User flagged as uncertain',
            button: 'Okay'
          });
          this.approval = this.resetForm(this.approval);
          this.getUser();
        },
        error: error => {
          this.approval.error = error;
        }
      });
      this.approval.loading = false;
    },
    async toggleDebitCardActivation() {
      this.debitCardForm.loading = true;
      await this.sendRequest('admin.users.activateDebitCard', {
        data: {
          user_id: this.staff.id,
          status: this.debitCardForm.data.status.value
        },
        success: () => {
          this.getUser();
        },
        error: error => {
          this.statusForm.error = error;
        }
      });
      this.getUser();
      this.debitCardForm.loading = false;
    },
    async updateUserProfile() {
      const phoneNumbers = this.additionalPhoneForm.data.additionalPhone.value.split(
        ','
      );
      const validateNumbers = this.checkPhone(phoneNumbers);
      if (!validateNumbers) {
        this.additionalPhoneError =
          'Invalid phone number provided, seperate with comma.';
        return;
      }
      this.additionalPhoneError = '';
      this.additionalPhoneForm.loading = true;
      await this.sendRequest(
        'admin.users.profile.updateAdditionalPhone',
        this.staff.id,
        {
          data: {
            additionalPhone: phoneNumbers
          },
          success: () => {
            this.getUser();
            this.additionalPhoneForm.changed = false;
            this.$success({
              title: 'Operation Successful',
              body: "User's phone numbers updated",
              button: 'Okay'
            });
          },
          error: error => {
            this.statusForm.error = error;
          }
        }
      );
      this.getUser();
      this.additionalPhoneForm.loading = false;
    }
  }
};
</script>
